"use client"
import { EMAIL_URL, SITE_TLD } from "../../const"
import { useI10n } from "@local/i10n"

const enAndEu = (country: string) => {
  const companyAddress =
    country === "eu"
      ? `<p>Global Innovation Ventures AG<br/>[Re: Privacy Compliance Officer]<br/>c/o AbaFin Treuhand AG - Baarerstrasse 82 - Zug , ZUG, 6302 - Switzerland</p>`
      : `<p>Digital Advice LLC<br/>[Re: Privacy Compliance Officer]<br/>
    16192 Coastal Highway 19958 Lewes - Delaware - USA</p>`

  return `<h1 id="privacy-policy">${SITE_TLD} - Privacy Policy</h1>
  <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from ${SITE_TLD} (the “Site”).</p>
  <h2 id="personal-information-we-collect">PERSONAL INFORMATION WE COLLECT</h2>
  <p>When you visit the Site, we automatically collect specific information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.</p>
  <p>We collect Device Information using the following technologies:</p>
  <ul>
  <li><p>“Cookies” are data files that are placed on your device or computer and often include a unique anonymous identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.</p>
  </li>
  <li><p>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
  </li>
  <li><p>“Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.</p>
  </li>
  </ul>
  <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
  <p>_When you visit or log in to our website, cookies and similar technologies may be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email or home address. We (or service providers on our behalf) may then send communications and marketing to these email or home addresses. You may opt out of receiving this advertising by visiting https://app.retention.com/optout</p>
  <h2 id="how-do-we-use-your-personal-information-">HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
  <p>We use the Order Information that we generally collect to fulfil any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:</p>
  <ul>
  <li>Communicate with you;</li>
  <li>Screen our orders for potential risk or fraud; and</li>
  <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
  </ul>
  <p>We use cookies to collect information about abandoned shopping carts. Once the cart is considered abandoned, an email or SMS message will be sent as a reminder if you have signed up to receive these notices.</p>
  <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
  <h2 id="sharing-your-personal-information">SHARING YOUR PERSONAL INFORMATION</h2>
  <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
  <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or another lawful request for information we receive, or to protect our rights otherwise.</p>
  <p>Text marketing (if applicable): With your permission, we may send text messages about our store, new products, and other updates. Updates include Shopping Cart Reminders. [Cookies/Webhooks/Plugins, etc] will be used to trigger the Shopping Cart Reminders messaging system.</p>
  <p>Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the text message services.</p>
  <h2 id="behavioural-advertising">BEHAVIOURAL ADVERTISING</h2>
  <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.</p>
  <p>You can opt out of targeted advertising by using the links below:</p>
  <ul>
  <li>Facebook: <a href="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</a></li>
  <li>Google: <a href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a></li>
  <li>Bing: <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a></li>
  </ul>
  <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.</p>
  <h2 id="do-not-track">DO NOT TRACK</h2>
  <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
  <h2 id="your-rights">YOUR RIGHTS</h2>
  <p>If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information is corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
  <p>Additionally, if you are a European resident we note that we are processing your information to fulfil contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
  <h2 id="data-retention">DATA RETENTION</h2>
  <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
  <h2 id="changes">CHANGES</h2>
  <p>We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
  <h2 id="minors">MINORS</h2>
  <p>The Site is not intended for individuals under the age of 18.</p>
  <h2 id="contact-us">CONTACT US</h2>
  <p>If you have any further questions, please <a href="mailto:support@${EMAIL_URL}">click here</a> to contact us.</p>
  ${companyAddress}
  <p>*Please note, that above mentioned address is NOT a return address. For returns, please contact Customer Support).</p>
  `
}

export const privacy = {
  en: enAndEu("en"),
  eu: enAndEu("eu"),
}

export const PrivacyPage = () => {
  const { currencyCode } = useI10n()

  const localeToShow =
    currencyCode === "EUR" || currencyCode === "GBP" || currencyCode === "CHF"
      ? "eu"
      : "en"
  const data = privacy[localeToShow] ?? privacy["en"]

  return <div className="subpage" dangerouslySetInnerHTML={{ __html: data }} />
}
