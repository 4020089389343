"use client"
import { Modal } from "@local/ui/src/Modal"
import { privacy } from "./privacy"
import { termsAndConditions } from "./termsAndConditions"
import YoutubeEmbed from "../YoutubeEmbed"
import { usePageLayout, type Page } from "../../usePageLayout"
import { classNames } from "@local/utils"
import styles from "./styles.module.css"
import { EMAIL_URL, GET_ANY_CHANNEL_ID, SITE_URL } from "../../const"
import type { Payload } from "@local/payload-client/src/types"
import { useI10n } from "@local/i10n/src/useI10n"
import Image from "next/image"

interface Props {
  about: string
  storeId?: Payload.AnchorChannelId
}

export const SubPages = ({ about, storeId }: Props) => {
  const { showPage, togglePage } = usePageLayout()
  const { currencyCode } = useI10n()

  const localeToShow =
    currencyCode === "EUR" || currencyCode === "GBP" || currencyCode === "CHF"
      ? "eu"
      : "en"

  const { IS_HAM, IS_BAERSKIN, IS_COZI, IS_BOXERS, IS_HAM_ONLY } =
    GET_ANY_CHANNEL_ID(storeId)

  const calendarDays = IS_BAERSKIN ? "sixty (60)" : "fourteen (14)"

  const shippingReturns = IS_BAERSKIN
    ? `
      <h1><strong>Shipping, Exchanges &amp; Returns</strong></h1>
      <br/><h2><strong>When will my order arrive?</strong></h2>
      <p>From the moment you checkout to the second your package arrives at your door, it takes around 10 to 14 days.</p>
      <br/><h2><strong>Can I return a product?</strong></h2>
      <p>We are happy to let you know that our store has a 60-day return policy*. We've made the process as simple as possible; all you need to do is follow these steps:</p>
      <br/><h3><strong>Make sure you are eligible for a return or exchange</strong></h3>
      <p>For this, your item should be in original selling condition, which means:</p>
      <ul>
      <li>It is still in its original packaging with the tags and labels intact</li>
      <li>It is not damaged, stained, altered or washed</li>
      </ul>
      <p>When it comes to maintaining health and hygiene standards, we don&rsquo;t shy away from taking extra precautions. This is why we cannot accept the returns of lingerie, underwear, boxers or socks, nor any items that have been worn, washed or modified in any way. Of course, you can still try your product for fit and comfort, the same way you would at a fitting room in a store. We hope you understand.</p>
      <br/><h3><strong>Let us know</strong></h3>
      <p>If you wish to return or exchange an item, please contact us by emailing <a href="mailto:support@baerskintactical.com">support@baerskintactical.com</a>, stating the reasons for your return/exchange. Please make sure you contact us <strong>within 60 days* after your product reaches you</strong> to benefit from this policy. The delivery date, as stated by the shipping courier, is the one that counts!</p>
      <p>You will be contacted shortly by our customer support, and one of our agents will provide you with further information.</p>
      <p>Please don't ship any products before contacting us. <strong>We will not be able to make reimbursements for products shipped before contacting our team, nor items sent to any address other than the one provided by our support team.</strong></p>
      <p>Make sure to <strong>indicate your name and order ID</strong> in a clear way and place it within the parcel; this way we will be able to identify the package faster and process the refund. No need to ship it back by express post&mdash;if you contacted us within 60 days* of the delivery date, you&rsquo;re all set!</p>
      <p>Once we receive your item, we will inspect its condition. If everything is okay, we will initiate the refund using your original payment method.</p>
      <p>Every effort will be made to ensure a prompt refund. Returns are usually processed within 5-7 days after reception of the product, but it might take some additional time for the bank or card company to process the request.</p>
      <p>If you want to simply exchange the product for a better-fitting one, don&rsquo;t worry&mdash;our <strong>Fit Guarantee</strong> has got you covered! Contact our support team, and they will be able to assist you with the exchange.</p>
      <p><em>*</em><strong><em> Please note shipping charges incurred are not refundable.*</em></strong><strong><em><br /></em></strong><strong><em><br /></em></strong><strong><em>* If you made the purchase between October 1st and December of the same year, the return and exchange window extends to 31st January.</em></strong></p>
      <br/><h2><strong>What to do if my product arrives damaged</strong></h2>
      <p>We're committed to bringing you nothing but the best. Should you receive a damaged product, don't worry&mdash;we will make things right for you.</p>
      <p>Here is what you can do to help us help you:</p>
      <ul>
      <li>Please take a picture of the item where the damage is clearly represented. In order to help you out, we will need proof of the damage. Please help us by taking a picture or video, depending on what you need to demonstrate.</li>
      <li>Make sure you inform us as soon as possible. Please contact us by emailing <a href="mailto:support@baerskintactical.com">support@baerskintactical.com</a>. Our agents will contact you back, and we will work together to find the best suitable solution to solve this issue at our own expense.</li>
      </ul>
  `
    : `
    <h1><strong>Shipping & Returns</strong></h1>
    <h1>When will my order arrive?</h1><p>
    From the moment you checkout to the second your package arrives at your door, it takes around 10 to 14 days.</p>

    ${
      IS_BAERSKIN
        ? "<p>Items pre-ordered will be shipped on the date indicated on the page and checkout, and no sooner than that.</p>"
        : ""
    }

    <h2>Can I return a product?</h2><p>

  <p>We are happy to let you know that our store has a ${calendarDays} days return policy. We’ve made the process as simple as possible - you simply need to follow these steps:</p>
  <h3 id="1-make-sure-you-are-eligible-for-a-return">1. Make sure you are eligible for a return</h3>
  <p>For this, your item should be in original selling condition, which means:</p>
  <ul>
  <li>It should be in its original packaging with the tags and labels intact</li>
  <li>It shouldn’t be damaged, stained, altered or washed</li>
  </ul>
  ${IS_BOXERS ? "</br><p><strong>Important note: </strong>Due to hygiene reasons, we are unable to accept returns or exchanges of boxers, briefs and panties. Any of such items returned to us will be discarded and no refund will be issued.</p>" : "<p>When it comes to maintaining health and hygiene standards, we do not shy away from taking extra precautions in order to protect our customers. For this reason, we cannot accept returns of lingerie, underwear, or socks.</p>"} <h3 id="2-let-us-know">2. Let us know</h3>
  <p>If you wish to return an item, please contact us by sending an email to <a href="mailto:support@${EMAIL_URL}">support@${EMAIL_URL}</a>, stating the reasons for your return. Please make sure you contact us <b>within ${calendarDays} days after your product reaches you</b>, to benefit from the return policy. The delivery date, as stated by the shipping courier, is the one that counts!</p>
  <p>You will be contacted shortly by our customer support, that will provide you with a return form.</p>
  <p>Please don’t ship anything before contacting us. <b>We will not be able to make reimbursements for products shipped before contact with our team was established.</b></p>
  <h3 id="3-fill-out-the-form-and-check-the-return-address">3. Fill out the form and check the return address</h3>
  <p>Once you have filled out the form we emailed you, please print it out and put it inside the package. Make sure you identify the right return address: we will not accept returns at any other addresses.</p>
  <h3 id="4-ship-it">4. Ship it</h3>
  <p>You are now all set to send the package to our return center! No need to ship it back by express post - if you contacted us within ${calendarDays} days of the delivery date, and have received our return instructions from support team you’re all set!</p>
  ${!IS_COZI && !IS_BAERSKIN && !IS_HAM && "<p>Exception to this rule: Due to hygiene reasons, we are unable to accept returns or exchanges of briefs and panties. All briefs/panties returned to our return centers are not eligible for refunding, so the item(s) will be discarded and no refund will be issued.</p>"}
  <h3 id="5-receive-your-refund">5. Receive your refund</h3>
  <p>Once we receive your item, we will inspect its condition. If everything is OK we will initiate the refund to your original payment method.</p>
  <p>Every effort will be made to ensure a prompt refund. Returns are usually processed within 3-5 days after reception, but it might take additional time for the bank to process the request.</p>
  <p><b><i>* Please note shipping charges incurred are not refundable. *</i></b></p>
${
  IS_BAERSKIN
    ? "<p><b>NOTE: </b>Customized items are not eligible for return or free exchange. Items purchased for a company but are not customized can be returned as per usual return process described above. </p>"
    : ""
}

  <h2 id="what-to-do-if-my-product-arrives-damaged">What to do, if my product arrives damaged</h2>
  <p>We’re committed to bringing you nothing but the best. Should you have received a damaged product, don’t worry - we will make sure to make things right.</p>
  <p>Here is what you can do to help us assist you:</p>
  <h3 id="1-please-take-a-picture-of-the-damage">1. Please take a picture of the damage</h3>
  <p>In order to help you out, we will need proof of the damage. Please help us by taking a picture or video, depending on what you need to show.</p>
  <h3 id="2-make-sure-you-inform-us-as-soon-as-possible">2. Make sure you inform us as soon as possible</h3>
  <p>Please contact us by sending an email to <a href="mailto:support@${EMAIL_URL}">support@${EMAIL_URL}</a>. Our agents will contact you back and find the best suitable solution to correct our mistake at our expense.</p>
  <p><b><i>* Please note that FINAL SALE promotional items are not refundable.*</i></b></p>
  `

  const pages = {
    about: {
      title: "Who we are",
      content: about,
    },
    payments: {
      title: "Secure Payments",
      content: `<p>Our website <a href="${SITE_URL}">${SITE_URL}</a> is secured with 256-bit SSL Encryption Technology. This technology guarantees the complete integrity and confidentiality of any data entered on this website.</p>
      <p>Our available payment methods include all major credit and debit cards, the payment provider PayPal, and other local payment methods depending on your country of residence.</p>
      <p>Credit and debit card payments are processed directly by our card payment processor, Stripe. Stripe receives your payment information from this website using a 256-bit SSL Encrypted Connection.</p>
      <p><strong>How we handle your data:</strong></p>
      <p><strong>We do not save your payment information. Any credit/debit card data entered on this page will only be used to complete the current purchase. Any future purchase on this website will require entering your credit/debit card information again.</strong></p>
      <p><strong>We do save your contact and address information to make sure your package arrives safely to your door.</strong></p>
      `,
    },
    privacy: {
      title: "Privacy Policy",
      content: privacy[localeToShow],
    },
    shippingReturns: {
      title: "",
      content: shippingReturns,
    },
    terms: {
      title: "",
      content: termsAndConditions(localeToShow, { IS_BAERSKIN, IS_HAM }),
    },
  }

  const PageToShow = ({
    page,
    title,
    content,
  }: {
    page: Page
    title: string
    content: string
  }) => {
    return (
      <Modal open={true} toggle={() => togglePage(page)} title={title}>
        {IS_HAM_ONLY && showPage === "about" ? (
          <>
            <div className="visible block mb-6 md:invisible md:hidden">
              <YoutubeEmbed embedId="pKtNHVcuaiM" mobile />
            </div>
            <div className="invisible hidden mb-6 md:block md:visible">
              <YoutubeEmbed embedId="yMN0pYcPoS4" />
            </div>
          </>
        ) : null}
        {IS_BAERSKIN && showPage === "about" ? (
          <Image
            src={"/images/baerskin-about-us.jpg"}
            width={800}
            height={300}
            className="w-full my-4"
            alt="BÆRSkin About Us Image"
          />
        ) : null}

        <div
          className={classNames("subpage rtl:text-right", styles.InternalPages)}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Modal>
    )
  }

  if (showPage && showPage !== "none") {
    return (
      <PageToShow
        page={showPage}
        title={pages[showPage]?.title}
        content={pages[showPage]?.content}
      />
    )
  } else {
    return null
  }
}

export default SubPages
