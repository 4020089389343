import { classNames } from "@local/utils"

interface Props {
  embedId: string
  className?: string
  mobile?: boolean
  label?: string
  autoplay?: boolean
  mute?: boolean
  captions?: boolean;
  elementId?: string
  loop?: boolean
  allowControls?: boolean
}

const YoutubeEmbed = ({
  embedId,
  className,
  mobile,
  label,
  autoplay,
  mute,
  captions,
  elementId,
  loop,
  allowControls = true
}: Props) => {

  const allowAttrs = [
    "accelerometer",
    autoplay ? "autoplay" : "",
    "clipboard-write",
    "encrypted-media",
    "gyroscope",
    "picture-in-picture",
    mute ? "mute" : "",
  ].filter(Boolean).join(";")

  const srcParams = new URLSearchParams();
  if (autoplay) srcParams.set("autoplay", "1");
  if (mute) srcParams.set("mute", "1");
  srcParams.set("controls", allowControls ? "1" : "0");
  if (captions) srcParams.set("cc_load_policy", "1");
  if (loop) srcParams.set("loop", "1");
  if (loop) srcParams.set("playlist", embedId); // Required for loop to work
  if (loop) srcParams.set("modestbranding", "1");

  const srcUrl = `https://www.youtube.com/embed/${embedId}?${srcParams.toString()}`;

  return (
    <div
      className={classNames("relative", mobile ? "pb-[143.75%]" : "pb-[56.25%]")}
    >
      <iframe
        id={elementId}
        src={srcUrl}
        allow={allowAttrs}
        className={classNames(
          "absolute top-0 left-0 w-full h-full",
          className ?? ""
        )}
        title={label ?? "Youtube embedded video"}
        aria-label={label ?? "Youtube embedded video"}
      />
      {/**This div is necessary to block any click on the YT video player if not allowed*/}
      {!allowControls && <div
        className="absolute top-0 left-0 w-full h-full"
        style={{ zIndex: 10, backgroundColor: 'transparent' }}
      />}
    </div>
  )
}

export default YoutubeEmbed
