import { create } from "zustand"

export type Page =
  | "none"
  | "about"
  | "payments"
  | "privacy"
  | "shippingReturns"
  | "terms"

interface CartState {
  showPage: Page | null
  togglePage: (target?: Page) => void
}

export const usePageLayout = create<CartState>((set, get) => ({
  showPage: "none",
  togglePage: (target) => {
    const { showPage } = get()
    if (showPage === target) {
      return set({ showPage: null })
    } else {
      return set({ showPage: target })
    }
  },
}))
