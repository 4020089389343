import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import type { CartItem } from "@local/cart/src/types"

type GiftWrapItem = {
  index: number
  data: CartItem
}

type SetGiftWrapInput = {
  to: boolean
} & GiftWrapItem

export interface GiftWrapState {
  items: Array<GiftWrapItem>
  setItem: (input: SetGiftWrapInput) => Array<GiftWrapItem>
  getItem: (index: number) => GiftWrapItem | undefined
  clear: () => void
}

export const useGiftWrap = create<GiftWrapState>()(
  persist(
    (setState, getState) => ({
      items: [],
      setItem: ({ index, to, data }) => {
        const items = new Set(getState().items)
        if (to === true) {
          items.add({ data, index })
        } else {
          // sets don't compare objects. It can store the same object under different references.
          // thus we need the index for delete purposes
          items.forEach((item) => {
            if (item.index === index) {
              items.delete(item)
            }
          })
        }

        const arrayFromItems = Array.from(items)

        setState({
          items: Array.from(items),
        })

        return arrayFromItems
      },
      // somehow the items array is not fully available at some lifecycle events
      getItem: (index: number) => {
        return getState().items.find((item) => item.index === index)
      },
      clear: () => {
        setState({
          items: [],
        })
      },
    }),
    {
      name: "giftWrap",
      storage: createJSONStorage(() => localStorage),
      version: 2,
    }
  )
)
