"use client"
// TODO: Make this server
import Image from "next/image"
import {
  ImageAmex,
  ImageApple,
  ImageDiners,
  ImageDiscover,
  ImageGoogle,
  ImageKlarna,
  ImageMastercard,
  ImagePaypal,
  ImageVisa,
} from "./images"
import { useI10n } from "@local/i10n/src/useI10n"

interface Props {
  limit?: number
  fillHeight?: boolean
  noPaypal?: boolean
}

type CardItem = {
  src: typeof import("*.svg")
  alt: string
}

type CardProps = CardItem & Props

const Card = ({ src, alt, fillHeight }: CardProps) => (
  <div
    className="relative h-12 w-20 p-1 flex items-center"
    style={fillHeight ? { height: "100%", width: "auto" } : {}}
  >
    <Image
      src={src}
      alt={alt}
      style={fillHeight ? { height: "100%", width: "auto" } : {}}
    />
  </div>
)

const CreditCards = ({ limit, fillHeight, noPaypal }: Props) => {
  const { country } = useI10n()

  const cards: CardItem[] = [
    { src: ImageVisa, alt: "Visa" },
    { src: ImageMastercard, alt: "Mastercard" },
    { src: ImageAmex, alt: "American Express" },
    { src: ImageDiscover, alt: "Discover" },
    { src: ImageApple, alt: "Apple Pay" },
    { src: ImageDiners, alt: "Diners Club" },
    { src: ImageGoogle, alt: "Google Pay" },
  ]

  if (!noPaypal) {
    cards.push({ src: ImagePaypal, alt: "Paypal" })
  }

  if (process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID && country === "us") {
    cards.push({
      src: ImageKlarna,
      alt: "Klarna",
    })
  }
  return (
    <div
      className="flex w-full flex-row items-center justify-between space-x-2 align-middle bg-white rounded px-2"
      style={fillHeight ? { height: "100%" } : {}}
    >
      {cards.slice(0, limit ?? cards.length).map((card) => (
        <Card
          key={card.alt}
          src={card.src}
          alt={card.alt}
          fillHeight={fillHeight}
        />
      ))}
    </div>
  )
}

export { Card, CreditCards }
